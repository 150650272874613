





































































































import { Component, Provide, Vue } from 'vue-property-decorator';

import {
  Course,
  HttpClient,
  HttpError,
  Project,
  User,
  UserRoles,
} from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import { GlobalErrorsObserver, GlobalErrorsSubject, handle_global_errors_async } from '@/error_handling';

import { delete_all_cookies, get_cookie } from './cookie';
import UIDemos from './demos/ui_demos.vue';
import { BeforeDestroy, Created } from './lifecycle';
import { safe_assign } from './utils';


/* IMPORTANT! How to use the provided globals:
@Inject({from: 'globals'})
globals!: GlobalData;
// We need the provided globals to be one of our reactive data members,
// so we alias it here.
// ALWAYS ACCESS GLOBALS THROUGH THIS VARIABLE!
d_globals = this.globals;
*/
export class GlobalData {
  current_user: User | null = null;
  current_course: Course | null = null;
  current_project: Project | null = null;

  // User roles for the current user for the current course.
  user_roles: UserRoles = new UserRoles({
    is_admin: false,
    is_staff: false,
    is_student: false,
    is_handgrader: false,
  });

  async set_current_course(course: Course | null) {
    if (course === null) {
      this.user_roles = new UserRoles({
        is_admin: false,
        is_staff: false,
        is_student: false,
        is_handgrader: false,
      });
      this.current_course = null;
    }
    else {
      this.user_roles = await User.get_current_user_roles(course.pk);
      this.current_course = course;
    }

    this.current_project = null;
  }

  async set_current_project(project: Project, course?: Course) {
    if (course === undefined) {
      course = await Course.get_by_pk(project.course);
    }

    // Setting the current course makes some HTTP requests and sets
    // current_project to null, so we need this call to finish before
    // we set the current project.
    await this.set_current_course(course);
    this.current_project = project;
  }
}

@Component({
  components: {
    APIErrors,
  }
})
export default class App extends Vue implements GlobalErrorsObserver, Created, BeforeDestroy {
  /* IMPORTANT! How to use the provided globals:
  @Inject({from: 'globals'})
  globals!: GlobalData;
  // We need the provided globals to be one of our reactive data members,
  // so we alias it here.
  // ALWAYS ACCESS GLOBALS THROUGH THIS VARIABLE!
  d_globals = this.globals;
  */
  @Provide()
  globals: GlobalData = new GlobalData();

  d_global_error_count = 0;

  d_loading = true;

  @handle_global_errors_async
  async created() {
    GlobalErrorsSubject.get_instance().subscribe(this);
    try {
      // If no token is available, we want to just show the welcome
      // page and let the user click the sign in button to avoid login loops.
      if (get_cookie('token') !== null) {
        await this.login();
      }
    }
    finally {
      // If we encountered an error loading the current user,
      // we want that to be displayed.
      this.d_loading = false;
      await this.$nextTick();
    }
  }

  beforeDestroy() {
    GlobalErrorsSubject.get_instance().unsubscribe(this);
  }

  @handle_global_errors_async
  async login() {
    let auth_token = get_cookie('token');
    if (auth_token !== null) {
      HttpClient.get_instance().authenticate(auth_token);
    }

    try {
      this.globals.current_user = await User.get_current();
    }
    catch (e) {
      if (!(e instanceof HttpError) || e.status !== 401) {
        throw e;
      }
      // If logging in with the available token didn't work, delete
      // the token so that the user can start a fresh auth flow.
      // If no token is available, start the auth flow.
      if (auth_token !== null) {
        delete_all_cookies();
      }
      else {
        let oauth_url = e.headers['www-authenticate'].split('Redirect_to: ')[1];
        window.location.assign(oauth_url);
      }
    }
  }

  logout() {
    delete_all_cookies();
    this.globals.current_user = null;
  }

  handle_error(error: unknown): void {
    (<APIErrors> this.$refs.global_errors).show_errors_from_response(error);
  }
}

