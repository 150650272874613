import { render, staticRenderFns } from "./app.vue?vue&type=template&id=66f0f5e0&scoped=true&"
import script from "./app.vue?vue&type=script&lang=ts&"
export * from "./app.vue?vue&type=script&lang=ts&"
import style0 from "./app.vue?vue&type=style&index=0&id=66f0f5e0&lang=scss&scoped=true&"
import style1 from "./app.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f0f5e0",
  null
  
)

export default component.exports